import React, { useState, useEffect } from 'react';
import { doc, setDoc, collection, addDoc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "../../logic/initFirebase";

const messagesTest = [
  {
    cui: 123456,
    judet: 'Dolj',
    mesaj: 'Mesaj test ',
    nume: 'Firma test ',
    telefon: '0722422703',
  },
  {
    cui: 123456,
    judet: 'Dolj',
    mesaj: 'Mesaj test ',
    nume: 'Firma test ',
    telefon: '+40722422703',
  },
  {
    cui: 123456,
    judet: 'Dolj',
    mesaj: 'Mesaj test ',
    nume: 'Firma test ',
    telefon: '057ts22422703223',
  },
  {
    cui: 123456,
    judet: 'Dolj',
    mesaj: 'Mesaj test ',
    nume: 'Firma test ',
    telefon: '0722422703',
  },
  {
    cui: 123456,
    judet: 'Dolj',
    mesaj: 'Mesaj test ',
    nume: 'Firma test ',
    telefon: '0722422703',
  },
]

export function Test() {
  const [added, setAdded] = useState(false);

  const [data, setData] = useState();

  useEffect(() => {
    async function addTo() {
      let i = 0;
      for (let message of messagesTest) {
        const docSnap = await addDoc(collection(db, 'instantQue/0770285548/toBeSent'), {
          ...message,
          mesaj: message.mesaj + i,
          nume: message.nume + i,
          timestamp: new Date()
        });
        i++;
      }
      setAdded(true);
    }

    addTo();
  }, [])

  return (
    <div>{added ? 'Added' : 'Adding...'}</div>
  )
}
