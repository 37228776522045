import React, { useState } from 'react';
import './ClientPortal.scss';

export function ClientPortal() {
  const [sections, setSections] = useState({
    counties: [
      {
        name: "Dolj",
        value: "dolj",
        checked: false
      },
      {
        name: "Bucuresti",
        value: "bucuresti",
        checked: false
      },
      {
        name: "Timis",
        value: "timis",
        checked: false
      },
      {
        name: "Ilfov",
        value: "ilfov",
        checked: false
      },
      {
        name: "Aiud",
        value: "aiud",
        checked: false
      },
    ]
  });

  function handleCheckboxInputChange(target: React.ChangeEvent<HTMLInputElement>, section: 'counties') {
    const sectioned = [...sections[section]]
  }

  return (
    <div>
      <div className='side-bar-container'>
        <div className='filter-section'>
          <h1>Județ</h1>
          {sections.counties?.map(county => (
            <div className='input-container'>
              <input id={`judet-${county.value}`} type="checkbox" checked={county.checked} onChange={(e) => { handleCheckboxInputChange(e, 'counties') }} />
              <label htmlFor={`judet-${county.value}`}>{county.name}</label>
            </div>
          ))}
        </div>

        <div className='filter-section'>
          <h1>Localitate</h1>
          <div className='input-container'>
            <input id="localitate-craiova" type="checkbox" />
            <label htmlFor="localitate-craiova">Craiova</label>
          </div>

          <div className='input-container'>
            <input id="localitate-bucuresti" type="checkbox" />
            <label htmlFor="localitate-bucuresti">Bucuresti</label>
          </div>

          <div className='input-container'>
            <input id="localitate-timisoara" type="checkbox" />
            <label htmlFor="localitate-timisoara">Timisoara</label>
          </div>

          <div className='input-container'>
            <input id="localitate-craiova" type="checkbox" />
            <label htmlFor="localitate-craiova">Cluj</label>
          </div>

        </div>

        <div className='filter-section'>
          <h1>Cod Caen</h1>
          <div className='input-container'>
            <input id="caen-0112" type="checkbox" />
            <label htmlFor="caen-0112">0112 - Cultivarea orezului</label>
          </div>

          <div className='input-container'>
            <input id="caen-0620" type="checkbox" />
            <label htmlFor="caen-0620">0520 - Extractia carbunelui</label>
          </div>

          <div className='input-container'>
            <input id="caen-1420" type="checkbox" />
            <label htmlFor="caen-1420">1420 - Fabricarea articolelor din blana</label>
          </div>
        </div>

        <div className='filter-section'>
          <h1>Nr telefon</h1>
          <div className='input-container'>
            <input id="telefon-da" type="radio" name="telefon" />
            <label htmlFor="telefon-da">Da</label>
          </div>

          <div className='input-container'>
            <input id="telefon-nu" type="radio" name="telefon" />
            <label htmlFor="telefon-nu">Nu</label>
          </div>
        </div>
      </div>
    </div>
  )
}
