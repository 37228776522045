import { createBrowserRouter } from "react-router-dom";

import App from "./pages/App/App";
import { Test } from './pages/Test/Test';
import { ClientPortal } from "./pages/ClientPortal/ClientPortal";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/test",
    element: <Test />
  },
  {
    path: "/portal",
    element: <ClientPortal />
  }
])